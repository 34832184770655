import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../../components/modal/modal';
import api from "../../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    showToastArray,
} from '../../../../helpers/generals';
import { MaskCellPhone, formatarMilharReais } from '../../../../helpers/mascarasParaCampos';
import { encodeImageFileAsURL } from "../../../../helpers/filesBase64";

export default function ModalBusiness({
    reloadData2,
    toggle,
    isOpen,
    size,
    title,
}) {
    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [fileName, setFileName] = useState();
    const [fileB64, setFileB64] = useState();

    const [members, setMembers] = useState([]);

    const LoadMembers = async => {
        setMembers([]);
        api.get('/api/users/active/members').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setMembers(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    useEffect(() => {
        LoadMembers();
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: FormData.date || '',
            operation: FormData.operation || '',
            type: FormData.type || '',
            members_id: FormData.members_id || '',
            name: FormData.name || '',
            cnpj_cpf: FormData.cnpj_cpf || '', 
            cell_phone: FormData.cell_phone || '',
            email: FormData.email || '',
            business: FormData.business || '',
            description: FormData.description || '',
            amount: FormData.amount || '',
            fileName: fileName || '',
            file: fileB64 || '',
            is_active: 1,
        },
        validationSchema: Yup.object({
            date: Yup.string().required("Digite um dado."),
            operation: Yup.string().required("Selecione uma opção."),
            type: Yup.string().required("Selecione uma opção."),
            members_id: Yup.string().when('type', {
                is: (type) => FormData.type == 1,
                then: Yup.string().required("Selecione uma opção."),
                otherwise: Yup.string(),
            }),
            name: Yup.string().when('type', {
                is: (type) => FormData.type == 2,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),
            // cnpj_cpf: Yup.string().when('type', {
            //     is: (type) => FormData.type == 2,
            //     then: Yup.string().required("Digite esse campo."),
            //     otherwise: Yup.string(),
            // }),
            cell_phone: Yup.string().when('type', {
                is: (type) => FormData.type == 2,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),
            business: Yup.string().required("Selecione uma opção."),
            description: Yup.string().required("Digite um dado."),
            amount: Yup.string().required("Digite um dado."),
            fileName: Yup.string().required("Selecione um comprovante."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            api.post('/api/business/save', values).then(response => {
                return response.data;
            }).then(data => {
                if (data.success === true) {
                    showToastArray("", "Sucesso", title + " enviado com sucesso!");
                    setLoading(false);
                    reloadData2();
                }
            }).catch(error => {
                if (error) {
                    let message = 'Erro ao enviar o registro!';
                    if (error.response.data.message) {
                        message = error.response.data.message;
                    }
                    showToastArray("error", "Erro!", message);
                    setLoading(false);
                }
            });
        },
    });

    async function fileBase64Code(event) {
        event.persist();

        if (!event.target || !event.target.files) {
            return;
        }

        const fileList = event.target.files;
        const latestUploadedFile = fileList.item(fileList.length - 1);

        try {
            const fileContents = await encodeImageFileAsURL(latestUploadedFile);
            let b64 = '';
            let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
            let base64dataType = fileContents.split(';base64,')[0];
            base64dataType = base64dataType.split('/')[1];
            b64 = base64dataType + '##@@nedo##$$' + base64result;
            if (b64) setFileB64(b64);
        } catch (e) {
            // console.log(e);
        }
    };

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Adicionar | ' + title}
        >
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    console.log(validation.values);
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="date"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date': event.target.value }); }}
                                value={FormData.date ? FormData.date : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.date && validation.errors.date ? true : false
                                }
                            />
                            <Label>
                                Data
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="operation"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'operation': event.target.value }); }}
                                value={FormData.operation ? FormData.operation : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.operation && validation.errors.operation ? true : false
                                }
                            >
                                {validation.touched.operation && validation.errors.operation ? (
                                    <FormFeedback operation="invalid">{validation.errors.operation}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Venda </option>
                                <option value="2"> Compra </option>
                            </Input>
                            <Label>
                                Operação
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="type"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'type': event.target.value }); }}
                                value={FormData.type ? FormData.type : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Membro </option>
                                <option value="2"> Indicação Membro </option>
                            </Input>
                            <Label>
                                Tipo
                            </Label>
                        </FormGroup>
                    </Col>
                    {FormData && FormData.type ? (
                        <>
                            <Col sm="12" className="mb-1">
                                <h6>Selecionar membro:</h6>
                            </Col>
                            <Col sm="12"> 
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="members_id"
                                        type="select"
                                        onChange={(event) => { setFormData({ ...FormData, 'members_id': event.target.value }); }}
                                        value={FormData.members_id ? FormData.members_id : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.members_id && validation.errors.members_id ? true : false
                                        }
                                    >
                                        {validation.touched.members_id && validation.errors.members_id ? (
                                            <FormFeedback type="invalid">{validation.errors.members_id}</FormFeedback>
                                        ) : null}
                                        <option value=""> Selecione... </option>
                                        {members.map((row, index) => (
                                            <option key={index} value={row.id}>{row.name}</option>
                                        ))}
                                    </Input>
                                    <Label>
                                        Membro
                                    </Label>
                                </FormGroup>
                            </Col>
                        </>
                    ) : null}
                    {FormData && FormData.type && FormData.type == 2 ? (
                        <>
                            <Col sm="12" className="mb-1">
                                <h6>Dados da indicação:</h6>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="name"
                                        type="text"
                                        max={255}
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                        value={FormData.name ? FormData.name : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    <Label>
                                        Nome
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="cnpj_cpf"
                                        type="text"
                                        onChange={(event) => { setFormData({ ...FormData, 'cnpj_cpf': MaskCellPhone(event.target.value) }); }}
                                        value={FormData.cnpj_cpf ? FormData.cnpj_cpf : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.cnpj_cpf && validation.errors.cnpj_cpf ? true : false
                                        }
                                    />
                                    <Label>
                                        CNPJ / CPF
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="cell_phone"
                                        type="text"
                                        max={255}
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                                        value={FormData.cell_phone ? FormData.cell_phone : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                                        }
                                    />
                                    <Label>
                                        Celular
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <FormGroup floating>
                                    <Input
                                        autoComplete={false}
                                        name="email"
                                        type="email"
                                        max={255}
                                        maxLength={255}
                                        onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                                        value={FormData.email ? FormData.email : ''}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.nemailame && validation.errors.email ? true : false
                                        }
                                    />
                                    <Label>
                                        E-mail
                                    </Label>
                                </FormGroup>
                            </Col>
                        </>
                    ) : null}

                    <Col sm="12" className="mb-1">
                        <h6>Outros dados do negócio:</h6>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                name="business"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'business': event.target.value }); }}
                                value={FormData.business ? FormData.business : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.business && validation.errors.business ? true : false
                                }
                            >
                                {validation.touched.business && validation.errors.business ? (
                                    <FormFeedback type="invalid">{validation.errors.business}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Produto </option>
                                <option value="2"> Serviço </option>
                                <option value="3"> Serviço recorrente </option>
                            </Input>
                            <Label>
                                Negócio
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                name="description"
                                type="textarea"
                                style={{ height: '120px' }}
                                value={FormData.description ? FormData.description : ''}
                                onChange={(event) => { setFormData({ ...FormData, 'description': event.target.value }); }}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.description && validation.errors.description ? true : false
                                }
                            />
                            <Label>
                                Descrição do negócio
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                name="amount"
                                type="text"
                                value={FormData.amount ? FormData.amount : ''}
                                onChange={(event) => { setFormData({ ...FormData, 'amount': formatarMilharReais(event.target.value) }); }}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.amount && validation.errors.amount ? true : false
                                }
                            />
                            <Label>
                                Valor em R$
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Documento comprobatório
                                <span style={{ fontSize: '10px', color: 'red' }}> (Nota fiscal, contrato assinado ou proposta)</span>
                            </Label>
                            <Input
                                className="mb-0"
                                autoComplete={false}
                                name="file"
                                type="file"
                                onChange={(event) => {
                                    setFileName(event.target.value);
                                    fileBase64Code(event);

                                }}
                                value={fileName ? fileName : ''}
                                accept="image/jpeg,image/png,application/pdf"
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.fileName && validation.errors.fileName ? true : false
                                }
                            />
                            {validation.touched.fileName && validation.errors.fileName ? (
                                <FormFeedback type="invalid">{validation.errors.fileName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            Adicionar
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalBusiness.propTypes = {
    reloadData2: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
