import React, { useState, useEffect } from "react";
import {
  useNavigate, useParams,
} from 'react-router-dom';
import api from "../../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Label,
  Input
} from "reactstrap";

import LayoutHeader from "../../../../components/header";
import LayoutBannerHeader from "../../../../components/bannerHeader";

const ClassesView = props => {
  const navigate = useNavigate();

  const { id } = useParams();
 
  const [RowData, setRowData] = useState([]);
  const [Loading, setLoading] = useState(true);

  const LoadData = async => {
    setRowData([]); 
    api.get('/api/classes/show/' + id).then(response => {
      return response.data
    }).then(data => {
      if(data.success === true){
        setRowData(data.data);
        setLoading(false);
      }
    }).catch(error => {
      // console.log(error);
    })
  };  

  useEffect(() => {
    LoadData();
  }, []);

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      <Container>
        <Row>

          <LayoutBannerHeader show={true} />

          <Col md={12} className="perfil-box">
            <Row>

              <Col md={12} className="content-box">
                <Row>
                  <Col md={12} className="text-center">
                    <div className="titulo-hr">
                      <span>Aula</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="content-box"></Col>
              <Col md={8} className="content-box">
                <Row>
                  <Col md={12}>
                    <div className="boxes">
                      <Row>
                        <Col md={12} className="mb-3">
                          <h5>{RowData.name}</h5>
                          <h4>{RowData.date_extension}</h4>
                        </Col>
                        <Col md={12}>
                          <iframe width="100%" height="460" src={"https://www.youtube.com/embed/" + RowData.yotube_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col md={12} className="text-center">
                        <div className="titulo-hr">
                          <span>Descrição</span>
                        </div>
                      </Col>
                    </Row>

                    <div className="boxes">
                      <Row>
                        <Col md={12}>
                          <p dangerouslySetInnerHTML={{ __html:RowData.description}}></p>
                        </Col>
                        <Col md={12}>
                          <Row className="mt-3">
                            <Col size={12}>
                              <Button className="box-like">
                                <i class='bx bx-like'></i> <span>Gostei</span>
                              </Button>
                              <Button className="box-like">
                                <i class='bx bx-like'></i> <span>Salvar</span>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col md={12} className="text-center">
                        <div className="titulo-hr">
                          <span>Comentário(s)</span>
                        </div>
                      </Col>
                    </Row>

                    <div className="boxes mb-5">
                      <Row>
                        <Col md={12} className="sidebar-box">
                          <Row>
                            <Col xs={12}>
                              <Row>
                                <Col md={12}>
                                  <p>Comentário</p>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12}>
                              <FormGroup>
                                <Input type="textarea" name="text" id="exampleText" />
                              </FormGroup>
                              <Button>Enviar</Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12} className="sidebar-box">
                          <hr />
                        </Col>
                        <Col md={12} className="sidebar-box">
                          <Row>
                            <Col xs={1}>
                              <img class="sidebar-avatar" src="https://api.empresariossa.com/storage/images/avatar/20230830-680999ddb5032f842158594e0afe5af0.png" />
                            </Col>
                            <Col xs={11}>
                              <Row>
                                <Col md={12}>
                                  <h3>Jonata Feltrin</h3>
                                </Col>
                                <Col md={12}>
                                  <p>Etarget</p>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} className="mt-3">
                              <p>Qui omnis quam rem sunt dignissimos hic eligendi voluptas. Aut velit voluptate in Quis debitis aut aliquid maiores. Aut nihil voluptas rem mollitia praesentium ut repellendus nobis rem cupiditate voluptatem rem dolores rerum sit doloremque facilis. Ut dolor nobis id perspiciatis voluptatibus et quia sapiente. Ut dolor nobis id perspiciatis voluptatibus et quia sapiente.</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12} className="sidebar-box">
                          <hr />
                        </Col>                                          
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="content-box"></Col>
            </Row>
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ClassesView;