import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Row,
  Col,
  Button,
  Table,
  Spinner,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import LayoutHeader from "../../../components/header";
import SwalDeleteItem from "../../../helpers/swal";
import ModalIndications from "./modal/indications";
import { DateToData, MaskCellPhone } from "../../../helpers/mascarasParaCampos";

const MembersIndications = props => {

  let NameModule = 'Recomendações / Indicações';

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);
  const [FormData, setFormData] = useState({});

  const [ModalOpen, setModalOpen] = useState('');
  const [ModalId, setModalId] = useState(0);

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "type": FormData.type || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/indications/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  function openModal() {
    setModalOpen('add');
  }

  function reloadData2() {
    setModalOpen('');
    setModalId(0);
    LoadData(PaginationPageActual);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      {ModalOpen === 'add' ? (<ModalIndications
        toggle={() => setModalOpen(!ModalOpen)}
        isOpen={ModalOpen}
        size="md"
        title={NameModule}
        reloadData2={reloadData2}
      />) : ''}

      {ModalOpen === 'approved' ? (<SwalDeleteItem
        PathApi="/api/indications/approved/"
        DeleteId={ModalId}
        MethodApi="put"
        title="Aprovar indicação"
        text="Você tem certeza que deseja aprovar esta indicação?"
        isConfirmedText="Indicação aprovada com sucesso."
        reloadData={reloadData2}
      />) : ''}

      {ModalOpen === 'del' ? (<SwalDeleteItem
        PathApi="/api/indications/delete/"
        DeleteId={ModalId}
        reloadData={reloadData2}
      />) : ''}      

      <div className="box-container">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="type"
                        className="type"
                        name="type"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'type': event.target.value });
                        }}
                        value={FormData.type ? FormData.type : ''}
                      >
                        <option value=""> Todos </option>
                        <option value="1"> Recebido </option>
                        <option value="2"> Realizada </option>
                      </Input>
                      <Label>
                        Tipo
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={8}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      color="success"
                      onClick={() => {
                        openModal();
                      }}
                    >
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th> </th>
                      <th> Data </th> 
                      <th> Tipo </th>
                      <th> Membro </th>
                      <th> Nome </th>
                      <th> Celular </th>
                      <th> E-mail </th>
                      <th> Necessidade </th>
                      <th> Cadastro </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.points_checked > 0 ? (
                                row.points_checked == 1 ? (
                                  'Aprovado'
                                ) : (
                                  '-'
                                )                                
                              ) : (
                                (row.members_id && row.members_id == localStorage.getItem('UserID')) ? (
                                  <Button
                                    color="success"
                                    style={{ padding: '7px 5px 10px 5px', margin: '0px', lineHeight: '5px' }}
                                    onClick={() => {
                                      setModalId(row.id);
                                      setModalOpen('approved');
                                    }}
                                  >
                                    Aprovar
                                  </Button>
                                ) : (
                                  <Button
                                    color="danger"
                                    style={{ padding: '7px 5px 10px 5px', margin: '0px', lineHeight: '5px' }}
                                    onClick={() => {
                                      setModalId(row.id);
                                      setModalOpen('del');
                                    }}
                                  >
                                    Excluir
                                  </Button>
                                )
                              )}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.date ? DateToData(row.date, "/") : '-'}
                            </td>                          
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.type && row.type == 1 ? (<span style={{ color: 'orange' }}> Recebido </span>) : null}
                              {row.type && row.type == 2 ? (<span style={{ color: 'purple' }}> Realizada </span>) : null}
                            </td>                                
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.members ? row.members.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.name ? row.name : '-'}
                            </td>                                                        
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.cell_phone ? MaskCellPhone(row.cell_phone) : '-'}
                            </td>                        
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.email ? row.email : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.note ? row.note : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.created_at ? row.created_at : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersIndications;