import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormGroup,
    Button,
    FormFeedback,
    Label,
} from "reactstrap";
import ModalBase from '../../../components/modal/modal';
import api from "../../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    DateToData,
    desformatarMilharesReais,
    formatarMilharReais,
} from '../../../helpers/mascarasParaCampos';
import {
    showToastArray,
} from '../../../helpers/generals';
import { encodeImageFileAsURL } from "../../../helpers/filesBase64";
import ModalMarketingSponsors from '../sponsors/modal';

export default function ModalMarketingAdverts({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Loading, setLoading] = useState(false);

    const [SponsorsData, setSponsorsData] = useState([]);
    const [MethodsPaymentsData, setMethodsPaymentsData] = useState([]);
    const [FileB64, setFileB64] = useState();
    const [Img, setImg] = useState();

    const [total, setTotal] = useState('');
    const [parcels, setParcels] = useState(0);
    const [installmentsDetailed, setParcelsDetalhadas] = useState([]);
    const [valueParcel, setValueParcel] = useState(0);    

    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState();

    const LoadSponsors = async => {
        setSponsorsData([]);
        api.get('/api/sponsors/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setSponsorsData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    const LoadMethodsPayments = async => {
        setSponsorsData([]);
        api.get('/api/methods-payments/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setMethodsPaymentsData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    }; 

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/adverts/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {

                        const formData = { ...FormData };
                        
                        formData.installmentsDetailedEdit = [];

                        if (data.data.data) {

                            const item = data.data.data;
                            
                            setParcels(item.total_parcels);                            

                            if (item.date_i) formData.date_i = item.date_i;
                            if (item.date_f) formData.date_f = item.date_f;

                            if (item.value) formData.value = formatarMilharReais(item.value);

                            formData.type_pagament = item.type_pagament;
                            formData.type_pagament = item.type_pagament;
                            formData.sponsors_id = item.sponsors_id;
                            formData.is_active = item.is_active;
                            console.log('item.charges', item.charges);
                            formData.installmentsDetailedEdit = item.charges;

                            setImg(item.image);
                        }

                        setFormData(formData);
                        setLoading(false);
                    }
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadDataShow();
        LoadSponsors();
        LoadMethodsPayments();
    }, [parcels, modalId]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {

            date_i: FormData.date_i || '',
            date_f: FormData.date_f || '',
            value: FormData.value || '',

            sponsors_id: FormData.sponsors_id || '',
            type_pagament: FormData.type_pagament || '',

            fileName: FormData.fileName || '',
            file_b64: FileB64 || '',

            total_parcels: parcels || '',
            value_parcel: valueParcel || '',
            installmentsDetailed: installmentsDetailed || '',

            is_active: FormData.is_active || '',
        },
        validationSchema: Yup.object({
            sponsors_id: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Selecione uma opção."),
                otherwise: Yup.string(),
            }),  
            total_parcels: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Selecione uma opção."),
                otherwise: Yup.string(),
            }),  
            type_pagament: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Selecione uma opção."),
                otherwise: Yup.string(),
            }),                                    
            date_i: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }), 
            date_f: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),            
            value_parcel: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Digite esse campo."),
                otherwise: Yup.string(),
            }),   
            fileName: Yup.string().when({
                is: (is_active) => !modalId,
                then: Yup.string().required("Selecione uma imagem de banner."),
                otherwise: Yup.string(),
            }),
            is_active: Yup.string().required("Selecione uma opção."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            if (modalId > 0) {
                api.patch('/api/adverts/update/' + modalId, values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            } else {
                api.post('/api/adverts/save', values).then(response => {
                    return response.data;
                }).then(data => {
                    if (data.success == true) {
                        showToastArray("", "Sucesso", "Cadastro atualizado com sucesso!");
                        setLoading(false);
                        reloadData();
                    }
                }).catch(error => {
                    if (error) {
                        let message = 'Erro ao enviar o registro!';
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        }
                        showToastArray("error", "Erro!", message);
                        setLoading(false);
                    }
                });
            }
        },
    });

    async function fileBase64Code(event) {
        event.persist();

        if (!event.target || !event.target.files) {
            return;
        }

        const fileList = event.target.files;
        const latestUploadedFile = fileList.item(fileList.length - 1);

        try {
            const fileContents = await encodeImageFileAsURL(latestUploadedFile);
            let b64 = '';
            let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
            let base64dataType = fileContents.split(';base64,')[0];
            base64dataType = base64dataType.split('/')[1];
            b64 = base64dataType + '##@@nedo##$$' + base64result;
            if (b64) setFileB64(b64);
        } catch (e) {
            // console.log(e);
        }
    };

    const handleTotalChange = (value) => {
        if (value) {
            setTotal(desformatarMilharesReais(value));
        }
    };

    const handleParcelasChange = (event) => {
        setParcels(parseInt(event.target.value));
    };

    const calcularParcelas = () => {
        if (parcels > 0 && FormData.value) {
            const valueParcelCalculado = total / parcels;
            setValueParcel(valueParcelCalculado.toFixed(2));
    
            const parcelsArray = [];
            for (let i = 0; i < parcels; i++) {
                parcelsArray.push({
                    id: i + 1,
                    value: valueParcelCalculado.toFixed(2),
                    due_date: '',
                    note: ''
                });
            }
            setParcelsDetalhadas(parcelsArray);   
        } else {
            setParcelsDetalhadas([]); 
        }
    };

    const handleDiaVencimentoChange = (event, index) => {
        const updatedParcelasDetalhadas = [...installmentsDetailed];
        updatedParcelasDetalhadas[index].due_date = event.target.value;
        setParcelsDetalhadas(updatedParcelasDetalhadas);
    };

    const handleObservacaoChange = (event, index) => {
        const updatedParcelasDetalhadas = [...installmentsDetailed];
        updatedParcelasDetalhadas[index].note = event.target.value;
        setParcelsDetalhadas(updatedParcelasDetalhadas);
    };

    // const handleRemoverParcela = (id) => {
    //     const updatedParcelasDetalhadas = installmentsDetailed.filter(parcela => parcela.id !== id);
    //     setParcelsDetalhadas(updatedParcelasDetalhadas);

    //     const valueTotalRestante = total - valueParcel;
    //     const valueParcelCalculado = valueTotalRestante / (parcels - 1);
    //     setValueParcel(valueParcelCalculado.toFixed(2));
    // };
    
    function reloadData2() {
        setModalType('');                                
        setModalOpen(false);
        LoadSponsors();
    }

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={modalId > 0 ? 'Editar | ' + title : 'Adicionar | ' + title}
        >

            {modalOpen ? (<ModalMarketingSponsors
                toggle={() => setModalOpen(!modalOpen)}
                isOpen={modalOpen}
                size="xl"
                title="Patrocinadores"
                reloadData={reloadData2}
            />) : ''}

            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    console.log('validation', validation.values);
                    return false;
                }}
            >
                <Row>
                    <Col sm="12">
                        <FormGroup floating>
                            <Input
                                className=""
                                autoComplete={false}
                                name="is_active"
                                type="select"
                                placeholder="Situação"
                                onChange={(event) => { setFormData({ ...FormData, 'is_active': event.target.value }); }}
                                value={FormData.is_active ? FormData.is_active : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.is_active && validation.errors.is_active ? true : false
                                }
                            >
                                {validation.touched.is_active && validation.errors.is_active ? (
                                    <FormFeedback type="invalid">{validation.errors.is_active}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                <option value="1"> Ativo </option>
                                <option value="2"> Inativo  </option>
                            </Input>
                            <Label>
                                Situação
                            </Label>
                        </FormGroup>
                    </Col>

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Dados do registro </span>
                        </div>
                    </Col>
                    <Col xs="2" className="text-center">
                        <Button
                            className="btnFilter"
                            disabled={modalId > 0 ? true : false}
                            color="success"
                            onClick={() => {
                                setModalType('add');                                
                                setModalOpen(true);
                            }}
                        >
                            Adicionar
                        </Button>
                    </Col>
                    <Col xs="10">
                        <FormGroup floating>
                            <Input
                                disabled={modalId > 0 ? true : false}
                                autoComplete={false}
                                name="sponsors_id"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'sponsors_id': event.target.value }); }}
                                value={FormData.sponsors_id ? FormData.sponsors_id : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type && validation.errors.type ? true : false
                                }
                            >
                                {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                                <option value=""> Selecione... </option>
                                {SponsorsData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            <Label>
                                Patrocinador
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                disabled={modalId > 0 ? true : false}
                                name="date_i"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date_i': event.target.value }); }}
                                value={FormData.date_i ? FormData.date_i : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.date_i && validation.errors.date_i ? true : false
                                }
                            />
                            {validation.touched.date_i && validation.errors.date_i ? (
                                <FormFeedback type="invalid">{validation.errors.date_i}</FormFeedback>
                            ) : null}
                            <Label>
                                Data Inicial
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                disabled={modalId > 0 ? true : false}
                                name="date_f"
                                type="date"
                                onChange={(event) => { setFormData({ ...FormData, 'date_f': event.target.value }); }}
                                value={FormData.date_f ? FormData.date_f : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.date_f && validation.errors.date_f ? true : false
                                }
                            />
                            {validation.touched.date_f && validation.errors.date_f ? (
                                <FormFeedback type="invalid">{validation.errors.date_f}</FormFeedback>
                            ) : null}
                            <Label>
                                Data Final
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                disabled={modalId > 0 ? true : false}
                                name="type_pagament"
                                type="select"
                                onChange={(event) => { setFormData({ ...FormData, 'type_pagament': event.target.value }); }}
                                value={FormData.type_pagament ? FormData.type_pagament : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.type_pagament && validation.errors.type_pagament ? true : false
                                }
                            >
                                <option value=""> Selecione... </option>
                                {MethodsPaymentsData.map((row, index) => (
                                    <option key={index} value={row.id}>{row.name}</option>
                                ))}
                            </Input>
                            {validation.touched.type_pagament && validation.errors.type_pagament ? (
                                <FormFeedback type="invalid">{validation.errors.type_pagament}</FormFeedback>
                            ) : null}
                            <Label>
                                Forma de pagamento
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>    
                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Valores e parcelamento </span>
                        </div>
                    </Col>                                
                    <Col sm="3">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                disabled={modalId > 0 ? true : false}
                                name="value"
                                type="text"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'value': formatarMilharReais(event.target.value) });
                                }}
                                onKeyUp={(event) => {
                                    handleTotalChange(event.target.value);
                                }}
                                value={FormData.value ? FormData.value : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.value && validation.errors.value ? true : false
                                }
                            />
                            {validation.touched.value && validation.errors.value ? (
                                <FormFeedback type="invalid">{validation.errors.value}</FormFeedback>
                            ) : null}
                            <Label>
                                Valor em R$
                            </Label>
                        </FormGroup>
                    </Col>                    
                    <Col sm="2">
                        <FormGroup floating>
                            <Input
                                autoComplete={false}
                                disabled={modalId > 0 ? true : false}
                                name="value"
                                type="number"
                                value={parcels}
                                onChange={handleParcelasChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.value && validation.errors.value ? true : false
                                }                                
                            />
                            <Label>
                                Parcela(s)
                            </Label>
                            {validation.touched.date_i && validation.errors.date_i ? (
                                <FormFeedback type="invalid">{validation.errors.date_i}</FormFeedback>
                            ) : null}                            
                        </FormGroup>
                    </Col>
                    {modalId > 0 ? 
                        null
                    : (
                        <Col sm="2">        
                            <Button 
                                className="btnFilter"
                                type="button" 
                                color="warning" 
                                onClick={()=>{ calcularParcelas() }} 
                                disabled={Loading || modalId > 0 ? true : false}
                            >
                                Calcular Parcelas
                            </Button>                                    
                        </Col>
                    )}
                </Row>
                <Row>
                    {modalId > 0 && parcels > 0 ? (
                        FormData.installmentsDetailedEdit.map((row, index) => (
                            <Col sm="12" key={index}>
                                <div style={{ width: '100%', background: '#fff', padding: '20px 20px 20px 20px', marginBottom: '10px', borderRadius: '10px', border: '1px solid #ccc' }}>
                                    <Row>
                                        <Col sm="1" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '4px 10px 10px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                <span style={{ fontSize: '11px', fontWeight: '500', padding: '0px', margin: '0px' }}>
                                                    Parcela:
                                                </span>
                                                <br/>                                                
                                                {row.parcel}x
                                            </div>
                                        </Col>
                                        <Col sm="2" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '4px 10px 10px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                <span style={{ fontSize: '11px', fontWeight: '500', padding: '0px', margin: '0px' }}>
                                                    Status:
                                                </span>
                                                <br/> 
                                                {row.status == 0 ? 'Em aberto' : '' }
                                                {row.status == 1 ? 'Vencido' : '' }
                                                {row.status == 2 ? 'Cancelado' : '' }
                                                {row.status == 6 ? 'Renegociado' : '' }
                                                {row.status == 9 ? 'Pago' : '' }
                                            </div>
                                        </Col>                                        
                                        <Col sm="2" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '4px 10px 10px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                <span style={{ fontSize: '11px', fontWeight: '500', padding: '0px', margin: '0px' }}>
                                                    Valor Parcela:
                                                </span>
                                                <br/>
                                                R$ {row.value}
                                            </div>
                                        </Col>
                                        <Col sm="2" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '4px 10px 10px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                <span style={{ fontSize: '11px', fontWeight: '500', padding: '0px', margin: '0px' }}>
                                                    Vencimento:
                                                </span>
                                                <br/>
                                                {DateToData(row.due_date, "/")}
                                            </div>
                                        </Col>  
                                        <Col sm="5" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '4px 10px 10px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                <span style={{ fontSize: '11px', fontWeight: '500', padding: '0px', margin: '0px' }}>
                                                    Obs.:
                                                </span>
                                                <br/>                                                
                                                {row.note ? row.note : '-'}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ))
                    ) : (
                        installmentsDetailed.map((parcela, index) => (
                            <Col sm="12" key={index}>
                                <div style={{ width: '100%', background: '#fff', padding: '20px 20px 6px 20px', marginBottom: '10px', borderRadius: '10px', border: '1px solid #ccc' }}>
                                    <Row>
                                        <Col sm="1" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '13px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                {index + 1}x
                                            </div>
                                        </Col>
                                        <Col sm="3" className="text-center">
                                            <div style={{ width: '100%', background: 'rgb(243, 242, 239)', fontSize: '20px', fontWeight: '700', padding: '13px 10px', borderRadius: '0.375rem', border: '1px solid #dee2e6' }}>
                                                R$ {parcela.value}
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    name="parcela.vencimento"
                                                    type="select"
                                                    value={parcela.due_date}
                                                    onChange={(event) => handleDiaVencimentoChange(event, index)}
                                                >
                                                    <option value=""> Selecione... </option>
                                                    <option value="5"> 5 </option>
                                                    <option value="10"> 10 </option>
                                                    <option value="15"> 15 </option>
                                                    <option value="20"> 20 </option>
                                                    <option value="25"> 25 </option>
                                                    <option value="30"> 30 </option>
                                                </Input>
                                                <Label>
                                                    Vencimento
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="5">
                                            <FormGroup floating>
                                                <Input
                                                    autoComplete={false}
                                                    name="parcela.note"
                                                    type="text"
                                                    value={parcela.note}
                                                    onChange={(event) => handleObservacaoChange(event, index)}
                                                />
                                                <Label>
                                                    Observação
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ))
                    )}                    

                    <Col md={12} className="text-center mt-2">
                        <div className="titulo-hr">
                            <span> Anexe a banner </span>
                        </div>
                    </Col>

                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Imagem de ter o tamanho exato 1200x230 pixels
                            </Label>
                            <Input
                                name="fileName"
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(event) => {
                                    setFormData({ ...FormData, 'fileName': event.target.value });
                                    fileBase64Code(event);
                                }}
                                value={FormData.fileName ? FormData.fileName : ''}
                                onBlur={validation.handleBlur}
                                invalid={
                                    validation.touched.fileName && validation.errors.fileName ? true : false
                                }
                            />
                            {validation.touched.fileName && validation.errors.fileName ? (
                                <FormFeedback type="invalid">{validation.errors.fileName}</FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    {Img ? (
                        <Col sm="12" style={{ padding: '50px' }}>
                            <a href={'https://api.empresariossa.com/storage/files/' + Img} target="_blank" rel="noreferrer">
                                <img src={'https://api.empresariossa.com/storage/files/' + Img} width="100%" style={{ border: '3px solid #ccc', borderRadius: '10px' }} />
                            </a>
                        </Col>
                    ) : null}
                </Row>
                <Row className="mt-3 mb-2">
                    <Col xs="6">
                        <Button type="submit" color="success" disabled={Loading ? true : false}>
                            {modalId > 0 ? 'Atualizar' : 'Adicionar'}
                        </Button>
                    </Col>
                    <Col xs="6" className="text-right pb-2">
                        <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                    </Col>
                </Row>
            </Form>
        </ModalBase>
    );
}

ModalMarketingAdverts.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
